<template>
  <v-card class="AuditEvaluation overflow-x-hidden" color="#EBFAFB" flat>
    <v-card-title class="pb-0">Audit</v-card-title>
    <v-card-text>
      <p v-if="!isGeneralType">
        Please answer the audit questions below, and the case status will be
        changed automatically.
      </p>
    </v-card-text>
    <div
      v-for="section of sections"
      :key="get(section, '_id', '')"
      class="AuditEvaluation-Section"
    >
      <h3 class="px-5 py-3 text-subtitle-2 font-weight-bold text-capitalize">
        {{ get(section, "title", "") }}
      </h3>

      <div
        v-for="item of section.criteria"
        :key="item._id"
        class="px-3 py-0 my-4"
      >
        <v-tooltip :disabled="isGeneralTooltipDisabled" start top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-radio-group
                :value="get(value, valuePath(item._id), null)"
                :disabled="disabled"
                class="Criteria text--primary"
                row
                hide-details
                @change="handleChange($event, item._id)"
              >
                <template #label>
                  <span class="text--primary font-weight-bold">
                    {{ get(item, "text", "") }}
                  </span>
                </template>
                <template v-if="isTargetType">
                  <v-radio label="Yes" :value="true" />
                  <v-radio label="No" :value="false" />
                </template>
                <template v-if="isScoreType">
                  <v-radio label="0" :value="0" />
                  <v-radio label="1" :value="1" />
                  <v-radio label="2" :value="2" />
                  <v-radio label="N/A" :value="-1" />
                </template>
                <template v-if="isGeneralType">
                  <v-radio
                    v-for="(auditOption, index) in onlyRadioOptions(
                      item.options
                    )"
                    :key="index"
                    :label="auditOption"
                    :value="GeneralAuditOptionMapping[auditOption]"
                  />
                </template>
              </v-radio-group>

              <v-text-field
                v-if="
                  isGeneralType &&
                  item?.options?.includes(GeneralAuditOptions.comment)
                "
                :disabled="disabled"
                label="Comment"
                class="ml-5"
                :value="get(value, commentsPath(item._id), null)"
                @change="handleChange($event, item._id)"
              />
            </div>
          </template>
          <span>Start Audit to be able to answer.</span>
        </v-tooltip>
      </div>
    </div>
  </v-card>
</template>

<script>
import {
  AuditTemplateTypes,
  GeneralAuditOptionMapping,
  GeneralAuditOptions,
} from "@/misc/constants";
import { isString, get, isNumber } from "lodash";

export default {
  props: {
    passed: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null,
    },
    evaluationRules: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: AuditTemplateTypes.default,
      validator: (value) => Object.values(AuditTemplateTypes).includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      GeneralAuditOptionMapping,
      GeneralAuditOptions,
    };
  },

  computed: {
    isTargetType() {
      return this.type === AuditTemplateTypes.target;
    },
    isScoreType() {
      return this.type === AuditTemplateTypes.score;
    },
    isGeneralType() {
      return this.type === AuditTemplateTypes.general;
    },
    sections() {
      return this.evaluationRules?.sections || [];
    },
    criteria() {
      return this.sections.map(({ criteria }) => criteria).flat();
    },
    isGeneralTooltipDisabled() {
      return !this.isGeneralType || !this.disabled || this.passed;
    }
  },

  mounted() {
    if (!this.value) {
      this.initialize();
    }
  },

  methods: {
    get,
    handleChange(val, key) {
      if (this.isGeneralType) {
        return this.$emit("input", {
          ...this.value,
          [key]: {
            value: isNumber(val)
              ? val
              : this.value[key]?.value || this.value[key]?.value === 0
              ? this.value[key]?.value
              : -1,
            comment: isString(val) ? val : this.value[key]?.comment || "",
          },
        });
      }

      this.$emit("input", { ...this.value, [key]: val });
    },
    initialize() {
      const value = this.criteria.reduce(
        (acc, criteria) => ({ ...acc, [criteria._id]: null }),
        {}
      );
      this.$emit("input", value);
    },
    valuePath(itemId) {
      return this.isGeneralType ? `${itemId}.value` : itemId;
    },
    commentsPath(itemId) {
      return `${itemId}.comment`;
    },
    onlyRadioOptions(options) {
      if (!options?.length) return [];
      return options.filter((op) => op !== GeneralAuditOptions.comment);
    },
  },
};
</script>

<style lang="scss">
.AuditEvaluation {
  border: 1px solid #01c6d0 !important;
  border-radius: 8px !important;
  max-height: 900px;
  overflow: scroll;

  &-Section h3 {
    background-color: rgba(1, 198, 208, 0.16);
  }

  .Criteria {
    legend {
      width: 100%;
      margin-bottom: 8px;
    }

    .v-label {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
</style>
